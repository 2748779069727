import MuiIconButton from '@mui/material/IconButton'

export default function ButtonIcon({ disabled, visible, className, ...props }) {
  const { current } = useLoading()
  disabled = !!(disabled || current)
  if (visible === undefined) visible = true
  if (!visible) return ''
  return (
    <div className={ className }>
      <MuiIconButton disabled={ disabled } { ...props } />
    </div>
  )
}
